@use "sass:math";

/*** Font size *************/
$smallScreenFontSize: 14px;
$smallScreenFontRemCalc: 14;

$normal-font-size: 13px;
$normal-font-size-rem: 13;
$normal-line-height: 16px;
$normal-icon-font-size: 16px;
$normal-icon-font-size-big: 32px;

$search-result-font-size: 12px;

$side-menu-font-size: 12px;
$search-filter-menu-font-size: 12px;

$normal-input-height: 40px;
/****margin and padding****/
$XS: 5px;
$S: 10px;
$M: 15px;
$L: 20px;
$XL: 40px;
$XXL:50px;

$largePadding: 30px;
$loginMargin: 30px;

$filterMenuMargin: $M;

$top-section-margin: 30px;

/*****border-radius ***********/
$border-radius-buttons: 3px;

/*** Screen size **********/
$searchFilterMenuCollapse: 887px;
$searchThumbs: 500px;
$searchTopMenuSmallScreen: 500px;
$searchTopMenuButtonsCollapse: 850px;
$searchTopMenuButtonsCollapseSmall: 570px;
$searchTopMenuButtonsMobile: 400px;
$searchListThumbs: 600px;
$searchListThumbsContainer: 1200px;

$collapseBigImage: 1200px;
$fieldSmall: 850px;
$textBreak: 550px;
$dynamicSelectTabs: 1800px;

$actionMenuCollapse: 760px;
$actionMenuSmallScreen: 300px;

$overviewSubMenuBreakPoint-1: 1500px;
$overviewSubMenuBreakPoint-2: 1300px;
$overviewSubMenuBreakPoint-3: 500px;
$overviewSubMenuBreakPoint-4: 400px;

$breakPoint-small: 642px;
$breakPoint-medium-up: 1025px;

$artifact-top-menu: 1580px; //1350px;

$main-menu-small: 1500px;

$editFields: $breakPoint-medium-up;
$fields-in-view: $fieldSmall;

$selector-breakpoint-1: 1400px;

$imageCarouselTopMenu-xs: 300px;
$imageCarouselTopMenu-s: 400px;

$imageZoomMenu: 600px;

$imageCarouselHeight: 900px;

$procedure-stepper-breakpoint: 1000px;

$admin-card-breakpoint: 490px;
/*********size/height/width*************/
$IconArrowDownSize: 10px;
$buttonMaxWidth: 76px;
$fieldTitleWidth: 174px;
$filterMenuWidth: 270px; //filter menu drop down
$standardHeight1: 35px;
$contentMenuHeight: 60px;
$listIconSize: 16px;

$mainMenuHeight: 50px;
$searchPageHeaderHeight: $mainMenuHeight;
$objectPageHeaderHeight: 32px;
$topHeaderHeight: $mainMenuHeight;
$searchPageHeaderMenuWidth: 240px;
$columnWidth: 301px;
$actionMenuHeight: 35px;
$filterMenuButton: $columnWidth;
$artifactTopRow: 50px;

$imgThumbnailWidth: 192px;
$imgThumbnailHeight: 144px;

$searchPage-actionMenu: $mainMenuHeight;
$searchPage-actionMenu-SmallScreen: 74px;

$select-option-max-width: 500px;

$dialog-medium: 500px;
$dialog-large: 900px;
$dialog-xlarge: 1200px;

$admin-user-profile-width: math.div($breakPoint-small, 2);
/***********************/
$hover2: 20%;
$hover: 10%;
$lighter: 20%;
$disabled: 70%;

/*****Colors************/
$orange-color: #ff6600;
$orange-color2: #BC7A00;
$orange-color3: #feb97e;  //authority-ribbon,
$dark-blue: #007eae;
$turquoise: #6DC8DD;

$light-green: #E1F9D7; //authority-ribbon, template-group-select-button
$green: #b2cba4;  //job-status, template-group-select-button
$dark-green: #6b895f; //template-group-select-button

$authority-ribbon-border:#a4a4a4;

$gray: #f6f6f6;
$gray2: #dfdfdf;
$gray3: #ccc;
$gray4: #efefef;
$gray5: #999;
$gray6: #bbbbbb;

$background: #f6f6f6;

$black: #000000;
$white: #fff;

$input-focus-bg-color: #fafafa;
$field-button: #ecf2f6;
$field-button-border: #8694a1;

$textColor: #333;
$textColorPassive: #666;
$textColorPlaceholderInput: #999;
$textColorGray: #ccc;

$borderColor: #d0d7dc;
$borderColor-top: #e6e6e6;

$stroked-button-border-color: rgba(0, 0, 0, 0.12);

$menu-background: #333333;
$image-background:#999999;

$menuButtonsColor: #718495;

$icon-grey: #666;
$icon-grey-hover: darken($icon-grey, $hover2);

$icon-accent-blue: #40c4ff;
$icon-blue: #0277bd;
$border-blue: #0277bd;

$bg-blue: #0277bd;
$bg-dark-blue: #01579b;
$checkbox-blue: #0277bd;

$main-menu-text-gray: #ccc;
$main-menu-vertical-line: #666;

$small-version: #eef3f5;
$medium-version: #b7c2c9;
$large-version: #96d06e;

$filter-menu-text-gray: #a4a4a4;
$filter-menu-icon-gray: #999; //#9a9a9a;
$filter-menu-vertical-line: #6a6a6a;
$filter-menu-background-color: #444949;
$filter-menu-background-color2: #3b4040;
$filter-menu-border: #1d1e1e;
$filter-menu-border-light: #565e5e; //#545c5c;
$filter-menu-scrollbar: #888888;
$filter-menu-scrollbar2: #3b3b3b;

$thumbnail-border: #cad4d4;

$focus-menu-background: #474f53;
$focus-menu-button: #576062; //#495c61;
$focus-menu-button-active: #2e363a; //#28383e;
$focus-menu-button-active-border: #576062;
$focus-menu-button-border: #28383e;
$focus-menu-popUp-button-gray: #576062;
$focus-menu-popUp-button-border: #393f41;
$focus-menu-popUp-getFocus-icon: #919696;
$focus-menu-option-border: #6c7275;

$content-menu-border: #bccfcf;
$content-menu-border-active: #0277bd;

$admin-border:#b3b4b4;

$top-header-background: #f5f7fb;
$top-header-border: $admin-border;
$top-header-breadcrumbs-button-icon: #8c8c8d;
$top-header-breadcrumbs-arrow: #acacad;

$blue1:#e0e7ed;
$blue2:#c2cfda;
$blue3:#d1dbe3;

$Image-carousel-border: #c2d6d6;
$Image-carousel-background-1: $gray4;
$Image-carousel-background-2: #f9f9f9;

$light-blue-button-bg: #e4f6fa; //situla
$light-blue-button-border: #6fd0e4; //situla
$light-blue-button-color: #318390; //situla

$field-select-options-arrow: $focus-menu-button;

$component-edit-blue-light: #e7ecef;
$component-edit-blue-dark: #4bb2d4;

$what-is-new-border: $component-edit-blue-light;
$what-is-new-background1: $input-focus-bg-color;
$what-is-new-background2: $background;

$searchActionMenuButton-disabled: #e0e0e0; //#c8d0d6;
$searchActionMenuButton-active: #0277bd;

$buttonBlue1-light: #abecff;
$buttonBlue1-dark: #89bece; //select in edit-fields
$buttonBlue1-text: #387997;

$buttonBlue2-light: #c1d8e0;
$buttonBlue2-dark: #a8b8be;
$buttonBlue-text: #70919d;

$autofill-yellow: #faffbd;
$editYellow1:#fbfba8;
$editYellow2:#f5f5de;

$procedure-border: #ced8dd;
$content-menu-vertical-bg: #e6edf0;
$content-menu-vertical-bg-active: #dde6e9;
$procedure-stepper-border: #c5e1e7;

$errorBackgroundColor: #FCC;
$errorColor: #C30;
$validBackgroundColor: #c8f7cd;
$validColor: #43924b;
$warningBackgroundColor: #f8f3d6;
$warningColor: #72511c;
$warning-border: #d2cea8;
$embedded-alert-info_background: #cce8f4;
$embedded-alert-info_border: #9ec1c7;
$embedded-alert-info_color: #386383;

$notification-closeColor: $textColorPassive;
$notification-textColor: $textColorGray;
$notification-titleColor: $textColorGray;
$notification-shadow: rgba(#333,0.5);
$notification-background: #333;
$notification-success: #33cc00;
$notification-warning: #ffcc00;
$notification-error: #ff3333;


/***************** new colors ********************************/
/******************* commons *******************/
$checkbox-black: #282828;

$edit-50: #E8EAED;
$edit-100: #E0E7ED;
$edit-200: #C3CFD8;
$edit-300: #AABACA;

$border-color: #E6E6E6;

$material-checkbox-border-color: #fafafa;
/*************** advanced search ***************/

$advanced-search-blue: #1AC6FF;
$advanced-search-highlight: #FFE3D0;
$advanced-search-bg-light: #FBFBFB;
$advanced-search-bg-dark: #E6E6E6;
$advanced-search-secondary-text: #AAA;

$advanced-search-grouping-letter-color: $advanced-search-blue;
$advanced-search-general-border-color: #EEE;
$advanced-search-active-border-color: $advanced-search-blue;
$advanced-search-field-background-color: #FFF;

/**************admin**********************/
$concept-table-background: #fbfbfb;
$mat-button-hover-blue-color: #e2f5fb;
$admin-edit-background: #fffaf6;
$admin-edit-border: #ffe2cf;
$concept-blue-light: #36e4ff;
$concept-blue-dark: #19c5ff;
$concept-selected-blue: #f2fafc;
$scrollbar-thumb-admin: #d5d5d5;
/*****************modal******************/
$modal-border: #ffe2cf;


/***** z-index **********************************/
$zIndex-mainMenu: 601;  /* 1 */

/*search*/
$zIndex-searchHeader: 599; /* 3 */
$zIndex-adminHeader: 600; /*also used in overview-concepts*/
$zIndex-sectionOpen: 400;
$zIndex-searchTopMenu: 101;
$zIndex-searchResultPager: 100;
$zIndex-searchResultPager-input: 10;
$zIndex-searchActionMenuContainer: 11;
$zIndex-searchActionMenu: 400;
$zIndex-errorReport: 10;
$zIndex-thumbnail-checkbox: 100;
$zIndex-searchThumbnailBig: 12;
$zIndex-thumbnailContainerSearch: 10;

/*filter-menu*/
$zIndex-nav: 600; /* 2 */
$zIndex-filterMenuMediumDown-content: 600; /* 2 */

/*overview-menu*/
$zIndex-overviewMenu-count: 100;

/*field*/
$zIndex-sectionMenu: 503;
$zIndex-field-dropDown: 502;
$zIndex-field-dropDown-before: 502;
$zIndex-field-dropDown-after: 501;

/*field-select*/
$field-select: 496;
$field-select-active: 502;

/*components*/
$zIndex-imgNav: 10;
$zIndex-deleteItemButton: 100;
$zIndex-confirmAnimation-before: 99;

/*action-menu*/
$zIndex-artifactActionMenuButton: 497;
$zIndex-artifactActionMenuButton-active: 512;
$zIndex-artifactActionMenuDropdown: 511;
$zIndex-artifactCurrentMenuContainer: 498;

/*date-picker*/
$zIndex-datePicker-input: 2;
$zIndex-datePicker-input-focus: 3;


/*commons*/
$zIndex-dynamicSelect-dropDown: 100;
$zIndex-thumbnailOverlay: 22;
$zIndex-cameraLiveVideo: 100;
$zIndex-cameraText: 1;

/*other */
$zIndex-dropDownMenuSettings: 400;
$zIndex-artifactImgContainer: 511;
$zIndex-object-page-search-input: 513;

/*overlays / other high z-index*/
$zIndex-dfTabMenu-dropDown: 1000;
$zIndex-appNotification: 1000;
$zIndex-stickyEditMenu: 507;
$zIndex-selector: 510;
$zIndex-mainContainer-overlay: 508;

$zIndex-play-button: 509;
//must have a lower z-index than $zIndex-selector, due to change media order

$zIndex-job-status-dropdown: 509;

$zIndex-message: 513;

$zIndex-tooltip: 499;
$zIndex-tooltipBefore: 2;
$zIndex-tooltipAfter:1;
