/*****************vendor prefix animation and transition*************************/

@mixin transform-mixin($expression) {
  -webkit-transform: $expression;
  -moz-transform: $expression;
  -ms-transform: $expression;
  -o-transform: $expression;
  transform: $expression;
}

/**************transition*******************/
@mixin transitionProperty($property) {
  -webkit-transition-property: $property;
  -moz-transition-property: $property;
  //-ms-transition-property: $property;
  -o-transition-property: $property;
  transition-property: $property;
}
@mixin transitionDelay($delay) {
  -webkit-transition-delay: $delay;
  -moz-transition-delay: $delay;
  //-ms-transition-delay: $delay;
  -o-transition-delay: $delay;
  transition-delay: $delay;
}
@mixin transitionDuration($duration) {
  -webkit-transition-duration: $duration;
  -moz-transition-duration: $duration;
  //-ms-transition-duration: $duration;
  -o-transition-duration: $duration;
  transition-duration: $duration;
}
@mixin transitionTimingFunction($easing) {
  -webkit-transition-timing-function: $easing;
  -moz-transition-timing-function: $easing;
  //-ms-transition-timing-function: $easing;
  -o-transition-timing-function: $easing;
  transition-timing-function: $easing;
}
@mixin transition-mixin($property, $duration, $easing:ease, $delay:false) {
  @include transitionProperty($property);
  @include transitionDuration($duration);

  @if($delay){
    @include transitionDelay($delay);
  }
  @if($easing){
    @include transitionTimingFunction($easing);
  }
}
/****************animation*************************/
@mixin animationProperty($property) {
  -webkit-animation-name: $property;
  -moz-animation-name: $property;
  -o-animation-name: $property;
  animation-name: $property;
}
@mixin animationDuration($duration) {
  -webkit-animation-duration: $duration;
  -moz-animation-duration: $duration;
  -o-animation-duration: $duration;
  animation-duration: $duration;
}
@mixin animationTimingFunction($easing) {
  -webkit-animation-timing-function: $easing;
  -moz-animation-timing-function: $easing;
  -o-animation-timing-function: $easing;
  animation-timing-function: $easing;
}
@mixin animationDelay($delay) {
  -webkit-animation-delay: $delay;
  -moz-animation-delay: $delay;
  -o-animation-delay: $delay;
  animation-delay: $delay;
}
@mixin animationIteration($iteration) {
  -webkit-animation-iteration-count: $iteration;
  -moz-animation-iteration-count: $iteration;
  -o-animation-iteration-count: $iteration;
  animation-iteration-count: $iteration;
}
@mixin animationDirection($direction) {
  -webkit-animation-direction: $direction;
  -moz-animation-direction: $direction;
  -o-animation-direction: $direction;
  animation-direction: $direction;
}
@mixin animationFillMode($fillMode) {
  -webkit-animation-fill-mode: $fillMode;
  -moz-animation-fill-mode: $fillMode;
  -o-animation-fill-mode: $fillMode;
  animation-fill-mode: $fillMode;
}
@mixin animationPlayState($playState) {
  -webkit-animation-play-state: $playState;
  -moz-animation-play-state: $playState;
  -o-animation-play-state: $playState;
  animation-play-state: $playState;
}
@mixin animation-mixin($property, $duration, $easing:ease, $fillMode:none, $delay:0s,
$iteration:1, $direction:normal, $playState:running) {
  @include animationProperty($property);
  @include animationDuration($duration);

  @if($delay){
    @include animationDelay($delay);
  }
  @if($easing){
    @include animationTimingFunction($easing);
  }
  @if($iteration){
    @include animationIteration($iteration);
  }
  @if($direction){
    @include animationDirection($direction);
  }
  @if($fillMode){
    @include animationFillMode($fillMode);
  }
  @if($playState){
    @include animationPlayState($playState);
  }
}
@mixin keyframes-mixin($animationName) {
  @-webkit-keyframes #{$animationName}{
    @content;
  }

  @-moz-keyframes #{$animationName}{
    @content;
  }

  @-o-keyframes #{$animationName}{
    @content;
  }
  @keyframes #{$animationName}{
    @content;
  }

}
