/* hand-picked colors for Primus */
/* variables was first generated from https://themes.angular-material.dev/ then manually adjusted */
/* work in progress as of 11-2024 */
/* only light mode variables have been adjusted as of 11-2024 */

:host, :root {
  --sys-primary: rgb(49 98 141);
  --sys-surface-tint: rgb(49 98 141);
  --sys-on-primary: rgb(255 255 255);
  --sys-primary-container: rgb(207 229 255);
  --sys-on-primary-container: rgb(0 29 52);
  --sys-secondary: rgb(0, 99, 155);
  --sys-on-secondary: rgb(255 255 255);
  --sys-secondary-container: rgb(194, 231, 255);
  --sys-on-secondary-container: rgb(15 29 42);
  --sys-tertiary: rgb(2, 103, 125);
  --sys-on-tertiary: rgb(255 255 255);
  --sys-tertiary-container: rgb(179, 235, 255);
  --sys-on-tertiary-container: rgb(0, 78, 95);
  --sys-error: rgb(212, 61, 28);
  --sys-on-error: rgb(255 255 255);
  --sys-error-container: rgb(255, 218, 214);
  --sys-on-error-container:rgb(65, 0, 2);
  --sys-background: var(--sys-surface);
  --sys-on-background: rgb(24 28 32);
  --sys-surface: rgb(247 249 255);
  --sys-on-surface: rgb(24 28 32);
  --sys-surface-variant: rgb(222 227 235);
  --sys-on-surface-variant: rgb(66 71 78);
  --sys-outline: rgb(114 119 127);
  --sys-outline-variant: rgb(194 199 207);
  --sys-shadow: rgb(0 0 0);
  --sys-scrim: rgb(0 0 0);
  --sys-inverse-surface: rgb(45 49 53);
  --sys-inverse-on-surface: rgb(239 241 246);
  --sys-inverse-primary: rgb(157 203 251);
  --sys-primary-fixed: rgb(207 229 255);
  --sys-on-primary-fixed: rgb(0 29 52);
  --sys-primary-fixed-dim: rgb(157 203 251);
  --sys-on-primary-fixed-variant: rgb(18 74 115);
  --sys-secondary-fixed: rgb(213 228 247);
  --sys-on-secondary-fixed: rgb(15 29 42);
  --sys-secondary-fixed-dim: rgb(185 200 218);
  --sys-on-secondary-fixed-variant: rgb(58 72 87);
  --sys-tertiary-fixed: rgb(240 219 255);
  --sys-on-tertiary-fixed: rgb(35 21 50);
  --sys-tertiary-fixed-dim: rgb(212 190 230);
  --sys-on-tertiary-fixed-variant: rgb(80 64 96);
  --sys-surface-dim: rgb(211, 219, 229);
  --sys-surface-bright: rgb(255 255 255);
  --sys-surface-container-lowest: rgb(255 255 255);
  --sys-surface-container-low: hsl(218, 60%, 96%);
  --sys-surface-container: hsl(212, 42%, 94%);
  --sys-surface-container-high: hsl(213, 43%, 93%);
  --sys-surface-container-highest: hsl(212, 24%, 89%);
  --md-extended-color-primus-success-color: rgb(45 106 69);
  --md-extended-color-primus-success-on-color: rgb(255 255 255);
  --md-extended-color-primus-success-color-container: rgb(177 241 194);
  --md-extended-color-primus-success-on-color-container: rgb(0 33 14);
  --md-extended-color-primus-warning-color: rgb(137 80 32);
  --md-extended-color-primus-warning-on-color: rgb(255 255 255);
  --md-extended-color-primus-warning-color-container: rgb(255 220 196);
  --md-extended-color-primus-warning-on-color-container: rgb(47 20 0);
}

.dark {
  --sys-primary: rgb(157 203 251);
  --sys-surface-tint: rgb(157 203 251);
  --sys-on-primary: rgb(0 51 85);
  --sys-primary-container: rgb(18 74 115);
  --sys-on-primary-container: rgb(207 229 255);
  --sys-secondary: rgb(185 200 218);
  --sys-on-secondary: rgb(36 50 64);
  --sys-secondary-container: rgb(58 72 87);
  --sys-on-secondary-container: rgb(213 228 247);
  --sys-tertiary: rgb(212 190 230);
  --sys-on-tertiary: rgb(57 42 73);
  --sys-tertiary-container: rgb(80 64 96);
  --sys-on-tertiary-container: rgb(240 219 255);
  --sys-error: rgb(255 180 171);
  --sys-on-error: rgb(105 0 5);
  --sys-error-container: rgb(147 0 10);
  --sys-on-error-container: rgb(255 218 214);
  --sys-background: rgb(16 20 24);
  --sys-on-background: rgb(224 226 232);
  --sys-surface: rgb(16 20 24);
  --sys-on-surface: rgb(224 226 232);
  --sys-surface-variant: rgb(66 71 78);
  --sys-on-surface-variant: rgb(194 199 207);
  --sys-outline: rgb(140 145 153);
  --sys-outline-variant: rgb(66 71 78);
  --sys-shadow: rgb(0 0 0);
  --sys-scrim: rgb(0 0 0);
  --sys-inverse-surface: rgb(224 226 232);
  --sys-inverse-on-surface: rgb(45 49 53);
  --sys-inverse-primary: rgb(49 98 141);
  --sys-primary-fixed: rgb(207 229 255);
  --sys-on-primary-fixed: rgb(0 29 52);
  --sys-primary-fixed-dim: rgb(157 203 251);
  --sys-on-primary-fixed-variant: rgb(18 74 115);
  --sys-secondary-fixed: rgb(213 228 247);
  --sys-on-secondary-fixed: rgb(15 29 42);
  --sys-secondary-fixed-dim: rgb(185 200 218);
  --sys-on-secondary-fixed-variant: rgb(58 72 87);
  --sys-tertiary-fixed: rgb(240 219 255);
  --sys-on-tertiary-fixed: rgb(35 21 50);
  --sys-tertiary-fixed-dim: rgb(212 190 230);
  --sys-on-tertiary-fixed-variant: rgb(80 64 96);
  --sys-surface-dim: rgb(16 20 24);
  --sys-surface-bright: rgb(54 57 62);
  --sys-surface-container-lowest: rgb(11 14 18);
  --sys-surface-container-low: rgb(24 28 32);
  --sys-surface-container: rgb(29 32 36);
  --sys-surface-container-high: rgb(39 42 47);
  --sys-surface-container-highest: rgb(50 53 58);
  --md-extended-color-primus-success-color: rgb(149 213 167);
  --md-extended-color-primus-success-on-color: rgb(0 57 29);
  --md-extended-color-primus-success-color-container: rgb(16 81 46);
  --md-extended-color-primus-success-on-color-container: rgb(177 241 194);
  --md-extended-color-primus-warning-color: rgb(255 183 129);
  --md-extended-color-primus-warning-on-color: rgb(78 37 0);
  --md-extended-color-primus-warning-color-container: rgb(109 58 9);
  --md-extended-color-primus-warning-on-color-container: rgb(255 220 196);
}

