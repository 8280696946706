.adminEventCreate{
  .field-level-1 {
    .selected-options-content li{
      width: initial;
    }
    @media only screen and (max-width: 1500px) {
      .edit-field__field-title,
      .edit-field__field-content,
      .edit-field-inline-array__container,
      .edit-field-inline-array__add-button {
        width: 100%;
      }
    }
  }
}
/********************************************************/
.object-view-and-edit {

  ::selection {
    background-color: $textColorPlaceholderInput;
    color:$white;
  }

  .field-level-1 {
    @media only screen and (max-width: 1400px) {
      .edit-field__field-title,
      .edit-field__field-content,
      .edit-field-inline-array__container,
      .edit-field-inline-array__add-button,
      .edit-field-inline-array__container{
        width: 100%;
      }
      .field-title-component{
        padding-bottom: $XS;
      }
    }
  }
}
/****************Used in several components********************/
.content-with-copy{
  width: 90% !important;
  float:left;
}

.copy-keep-checkbox{
  float: left;
  width: 10%;
  padding-left: $S;
  padding-top: $S;
}

/*********************************************************/
//.field-title-container{
//  &.titleWithoutContent { //display title as section title, eg. "Location code"
//    .field-title {
//      display: inline-block;
//      width: 100%;
//      margin-top: $large;
//      hr{
//        margin: $XS 0 0;
//        display: inline-block;
//        width: 100%;
//      }
//      .field-title-text{
//        @extend .bold;
//      }
//    }
//  }
//}


