@use "@angular/material" as mat;
@import "../../material-3-theme";

.underline-first-letter{
  &::first-letter{
    text-decoration: underline;
  }
}

.edit-field__field-content{
  &.noCopy{
    .edit-field-selected-options {
      @extend .disable-button;
    }
    .selected-concept {
      display: none;
    }
  }
}

/*******************concept-authority***************************/
.concept-authority{
  @include border-radius-mixin($S !important);
  display:inline-block !important;
  height: 8px;
  width: 8px;
  border: 1px solid $dark-blue;
  background-color: $dark-blue;
}
.concept-authority-left{
  @extend .concept-authority;
  margin-right: $XS;
}
.concept-authority-right{
  @extend .concept-authority;
  margin-left: $XS;
}
.edit-field-selected-options, .selected-concept{
  .concept-name{
    order: 3;
  }

  .concept-authority-left, .concept-authority-right{
    float: left;
    margin-left: 0;
    margin-right: $XS;
  }
  .select-options-icon{
    order:1;
  }
}
.selected-concept{
  .concept-name{
    flex: 1;
    @include ellipsis-mixin();
    display: inline-block;
  }
}
.selected-concept > span {
  @include ellipsis-mixin();
  max-width: 100%;
  display: inline-block;
}

.edit-field-selected-options > span,
.selected-concept .withAuthority{
  @include display-flex();
  @include align-flex-items(center);
}
.field-select-options__option{
  .concept-name{
    @include ellipsis-mixin();
    vertical-align: middle;
    display: inline-block;
    max-width: 100%
  }
}

/***************list-items***********************/

//***Empty img**

.imgIcon{
  color: color-mix(in srgb, var(--sys-outline-variant) 80%, transparent);
  font-size: 30px;
  width: 32px;
  height: 32px;
  margin: auto;
  &.text-blue {
    font-size: 48px;
    height: 48px;
    width: 48px;
  }
}
/******************************************/
.select-options-icon{ //concept with icon. Used in select-list
  font-size: $normal-icon-font-size !important;
  margin-right: $XS;
}

/**********************************/
.disable-button {
  @include box-shadow-mixin(none);
  cursor: not-allowed;
  pointer-events: none;
  opacity: .30;
  filter: alpha(opacity=30);
}
/*****************date picker**********************/
.field-date-input {
  input[type="text"] {
    position: relative;
    //z-index: $zIndex-datePicker-input;
    float: left;
    height: 40px;
    padding: $XS $M;
    margin-bottom: 0;
    border: 1px solid $gray3;
    line-height: $normal-line-height;
    font-size: $normal-font-size;
    @media screen and (max-width: $breakPoint-medium-up) {
      font-size: $smallScreenFontSize;
    }
    &:focus {
      z-index: $zIndex-datePicker-input-focus;
      border-color: $border-blue;
      outline: 0;
      box-shadow: inset 0 1px 1px rgba(0,0,0, .075),
      0 0 8px rgba(102,175,233, .6);
    }
  }

}
.mat-datepicker-toggle {
  .mat-mdc-icon-button{
    @include border-radius-mixin(0 !important);
    height: 100% !important;
    line-height: $normal-line-height !important;
    &:hover, &:active, &:focus{
      color: $textColor;
      background-color: $gray2;
      border-color: $gray3;
    }
  }
}
.mat-mdc-icon-button[disabled]{
  background-color: transparent;
  border-color: transparent;
}

/************modal****************************/
.modal-dialog {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
}

.modal-content{
  border: none !important;
  @include border-radius-mixin(0 !important);
}

.modal-header {
  padding: $M $L;
  margin:0 !important;
  display: block;
  border-bottom: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: relative;
  border-bottom: 1px solid;
  border-color: inherit;
  h5 {
    padding-top: $M;
    padding-bottom: $S;
    margin: 0;
  }
  h1{
    span{
      font-size: 1.5rem;
      line-height: 32px;
    }
  }
  .close{
    color: #bbb !important;
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.modal-footer {
  @include justify-content(flex-end);
  @include display-flex();
  @include align-flex-items(center);
  @include flex-flow(row wrap);
  border-top: 1px solid;
  border-color: inherit;
  min-height: 52px;
  padding: $M $L !important;
  margin: 0 !important;
  a{
    width: auto;
  }
  .marginRight {
    margin-right: $S;
  }
}

.modal-body {
  display: block;
  margin: 0 !important;
  padding: 0 !important;
  max-height: 65vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-color: inherit;
  //.subSec:first-of-type {
  //  padding-top: $L;
  //}
  h4, hr{
    display:none;
  }
  .dynamicSelect__dropdown{
    position: absolute;
    height: 109px;
    top: 38px;
    z-index: $zIndex-dynamicSelect-dropDown;
  }
  .field-content {
    width: 100%;
    float: left;
  }
  textarea[rows] {
    max-height: 122px;
    resize: none;
    overflow-y: auto !important;
    overflow-x: hidden;
  }
}
.modal-title {
  @include display-flex();
  i, .mat-icon{
    margin-right: $S;
  }
}
.modal-main-content{
  padding: $M $L;
}

.modal-body-edit-fields {
  overflow: auto;
  min-height: 500px;
  @media only screen and (max-height: 670px) {
    padding-top: 0;
    max-height: calc(100vh - 200px);
    min-height: 50px;
    padding-bottom: $L;
  }
}
.confirm-dialog,
.alert-dialog {
  width: $dialog-medium;
  .modal-header{
    margin: 0 !important;
  }
}

.leave-animation-backdrop {
  @include animation-mixin(dialog-leave, 2s, cubic-bezier(0.175, 0.885, 0.320, 1), forwards, 0s);
}

.leave-animation-dialog {
  @include animation-mixin(dialog-leave, 2s, cubic-bezier(0.175, 0.885, 0.320, 1), forwards, 0s);
}

@include keyframes-mixin(dialog-leave){
  0% { opacity: 1; }

  100% {
    opacity: 0;
    @include transform-mixin(skewY(-45deg) translate(200%) scale(0));
    -webkit-transform-origin: center top;
    transform-origin: center top;
  }
}

//.edit-dialog {
//  width: $dialog-large;
//}
//.upload-dialog {
//  width: $dialog-xlarge;
//}
//.report-dialog {
//  width: $dialog-xlarge;
//}
.annotation-dialog-container {
  width: $dialog-xlarge;
  height: 100%;
  @media only screen and (max-width: 800px) {
    max-width: 96vw !important;
  }
}
.progress-modal {
  // TODO(mat-3): override the (huge) global min-width from material-3-styles.scss
  --mat-dialog-container-min-width: 200px;
  .mat-mdc-dialog-container {
    padding: 0 !important;
  }
}
.inherit-border-color {
  border-color: inherit;
}
.confirm-dialog,
.alert-dialog,
.edit-dialog,
.upload-dialog,
.report-dialog,
.annotation-dialog-container {
  .mat-mdc-dialog-container{
    padding: 0;
  }
}
/********************************************/
.shaking{
  @include animation-mixin(shake, 0.2s, cubic-bezier(.36,.07,.19,.97), none, 0s, 2);
  @include transform-mixin(translate3d(0, 0, 0));
  backface-visibility: hidden;
}

@include keyframes-mixin(shake){
  0%, 90%{
    @include transform-mixin(translate3d(-1px, 0, 0));
  }
  20%, 80%{
    @include transform-mixin(translate3d(2px, 0, 0));
  }
  30%, 50%, 70%{
    @include transform-mixin(translate3d(-3px, 0, 0));
  }
  40%, 60%{
    @include transform-mixin(translate3d(3px, 0, 0));
  }
}
/***************************/
.overlay {
  background-color: rgba(0,0,0,0.4);
  position: fixed;
  top: $mainMenuHeight;
  bottom: 0;
  z-index: $zIndex-mainContainer-overlay;
  left: 0;
  right: 0;
  &.dialogOpen {
    z-index: 1001;
    top:0;
  }
}

.transparent-overlay{
  position: fixed;
  left: 0;
  right: 0;
  top:0;
  bottom:0;
  background-color: transparent;
  z-index: 500;
}

/*********clearInput*****************/
.clearInputContainer{
  position: relative;
  .clearInputField{
    width: 0;
    height: 0;
    position: absolute;
    right: 0;
    top: 0;
    display:none;
    z-index: 496;
    //padding: $S;
    .mat-icon {
      height: 15px;
      width: 15px;
      font-size: 15px;
    }
  }

  input:focus + .clearInputField{
    @include display-flex();
    @include align-flex-items(center);
    @include justify-content(center);
    height: 100%;
    width: 28px; //font-size + padding
  }
  &:active, &:hover, &:focus{
    .clearInputField{
      @include display-flex();
      @include align-flex-items(center);
      @include justify-content(center);
      height: 100%;
      width: 28px; //font-size + padding
    }
  }
  input::-ms-clear { // Remove IE default X
    display:none;
    width: 0;
    height: 0;
  }

}
/*******************select box********************/
.custom-select {
  display: inline-block;
  width: 100%;
  line-height: 1.5;
  color: $textColor;
  vertical-align: middle;
  border: 1px solid $gray3;
  @include border-radius-mixin(0.25rem);
  cursor: pointer;
  @include appearance-mixin(none);
  &:focus {
    border-color: $buttonBlue1-dark;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba($black, .075), 0 0 5px rgba(128,189,255, .5);
  }
  &:invalid{
    color: $textColorPlaceholderInput;
  }
  &__placeholder{
    @include display-flex();
    @include align-flex-items(center);
    position: absolute;
    top: 10px;
    left: 15px;
    color: $textColorPlaceholderInput;
    pointer-events: none;
  }
  &__placeholder-key{
    text-transform: lowercase;
  }
}
/*********************what's-new table*****************************/
.what-is-new {
  &__table{
    @include display-flex();
    @include flex-flow(column nowrap);
    &__row{
      @include display-flex();
      @include flex-flow(row nowrap);
      width: 100%;
    }
    &__row-header{
      .what-is-new__table__row-item {
        &:nth-child(2n){
          border: 1px solid $what-is-new-border;
          background-color: $small-version;
          order:2;
        }
        &:nth-child(3n){
          border: 1px solid $what-is-new-border;
          background-color: $large-version;
          order:3;
        }
        &:nth-child(4n){
          border: 1px solid $what-is-new-border;
          background-color: $medium-version;
          order:4;
        }
      }

    }
    &__row-content{
      border: 1px solid $what-is-new-border;
      &:nth-child(odd){
        background-color: $what-is-new-background1;
      }
      &:nth-child(even){
        background-color: $what-is-new-background2;
      }
      .what-is-new__table__row-item {
        &.large,
        &largeEmpty,
        &.small,
        &.smallEmpty,
        &.medium,
        &.mediumEmpty {
          @include display-flex();
          @include align-flex-items(center);
          @include justify-content(center);
        }
        &.large, &.largeEmpty{
          order:3;
          &:before{
            content:"";
            @include box-shadow-mixin(-6px 0 6px -6px $gray3 inset);
            @include calc-mixin(height, '100% + 2px');
            position: absolute;
            left: -4px;
            width: 6px;
            top:0;
          }
          &:after{
            content:"";
            @include box-shadow-mixin(6px 0 6px -6px $gray3 inset);
            @include calc-mixin(height, '100% + 2px');
            position: absolute;
            right: -6px;
            width: 6px;
            top:0;
          }
        }
        &.small, &.smallEmpty{
          order:2;
        }
        &.medium, &.mediumEmpty{
          order:4;
        }
      }
      &:last-of-type {
        .what-is-new__table__row-item {
          &.large, &.largeEmpty {
            @include box-shadow-mixin(0px 2px 6px -2px $gray3);
          }
        }
      }
    }
    &__row-item {
      font-size: 0.8125rem;
      padding: $S;
      @include flex-basis(150px);
      position: relative;
      text-align: center;
      line-height: 24px;
      &:first-of-type{
        @include flex-grow(1);
        text-align: left;
        order:1;
      }
      @media only screen and (max-width: 1000px) {
        @include flex-basis(100px);
      }
    }
    @media only screen and (max-width: 500px) {
      .what-is-new__table__row-item {
        max-width: 40px;
        padding: $S 0;
        font-size: 11px;
        line-height: 1.3;
        min-width: 40px;
        &:first-of-type{
          max-width: 500px;
        }
      }
      .what-is-new__table__row-header{
        .what-is-new__table__row-item {
          font-size: 9px;
        }
      }
    }
  }
}

/***************overruled classes ************************/
.field-highlighted{
  background-color:$autofill-yellow;
}
.field-highlighted + a + span + span {
  background-color: $autofill-yellow;
}
.object-url-content {
  &:hover, &:active, &:focus {
    .search-navigator-name span{ text-decoration: underline; }
  }
}
/********************************************/
.overview-fields {
  &__content-list {
    .small-4:first-of-type,
    .small-4:nth-of-type(2),
    .small-6:first-of-type {
      border-right: 1px solid $borderColor;
    }
    .small-4:nth-of-type(2),
    .small-4:last-of-type,
    .small-6:last-of-type {
      padding-left: $S;
    }
    @media only screen and (max-width: 1800px) {
      .small-4:last-of-type { display: none; }

      .small-4:nth-of-type(2){ border:none; }

      .small-4{ width: 50%; }
    }

    @media only screen and (max-width: 1300px) {
      .small-4,
      .small-6 {
        width: 100%;
      }
      .small-4:nth-of-type(2),
      .small-6:last-of-type {
        display: none;
      }
      .small-4:first-of-type,
      .small-6:first-of-type{
        border:none;
      }

    }
  }

  &__selector,
  &__folder{
    .small-4,
    .small-6{
      width: 100%;
    }
    .small-4:nth-of-type(2),
    .small-4:last-of-type,
    .small-6:last-of-type {
      display: none;
    }
  }
}
/*****************Placeholder when loading menu***************************/
.loading-operation-selector-vertical {
  background-color: $content-menu-vertical-bg;
  border-right: 1px solid $procedure-border;
  width: 100%;
  height: 100%;
  &__selected-first-menu {
    height: 80px;
    width: 80px;
    background-color: $white;
    border-bottom: 1px solid $procedure-border;
    position: relative;
    &.isEditing {
      background-color: $blue1;
    }
    &:before {
      content: '';
      width: 5px;
      height: 100%;
      background-color: $bg-blue;
      position: absolute;
      left: 0;
    }
  }
}
.loading-object-content-tabs {
  height: 60px;
  width: 100%;
  @include display-flex();
  border-bottom: 1px solid $content-menu-border-active;
  &__selected-first-menu {
    background: $white;
    height:61px;
    padding: 0 $L;
    border-top:1px solid $content-menu-border;
    border-right: 1px solid $content-menu-border-active;
    position: relative;
    @include display-flex();
    @include flex-flow(column);
    @include justify-content(center);
    @include align-flex-items(center);
    &:before {
      content: '';
      height: 4px;
      background-color: $bg-blue;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    span{ font-size: 13px; }

    .mat-icon {
      position: absolute;
      bottom: -4px;
      margin: 0 auto;
      left: 0;
      right: 0;
    }
    &.isEditing {
      background: $blue1;
      span, .mat-icon { opacity: 0.6; }
    }
  }
}
.loading-object-overview-fields {
  &__header {
    height: 32px;
    margin-top: 30px;
  }
  &__line {
    height: 47px;
    border-bottom: 1px solid $border-color;
  }
  &.isEditing {
    .loading-object-overview-fields__line {
      border-bottom: 1px solid $borderColor;
    }
  }
}
/********************************************/
.expired {
  color: $notification-error !important;
}
/******************report-menu**********************/
.report-headline{
  margin-bottom: $L;
  padding-bottom: $S;
  padding-top: $L;
}

/*************************************/
.verticalAlign-filterContent {
  @include display-flex();
  @include align-flex-items(center);
  vertical-align: middle;
  height: 16px;
}
/******************* selector *********************/
.side-selector {
  width: 50%;
  position:fixed;
  bottom:0;
  background-color: $component-edit-blue-light;
  z-index: $zIndex-selector;
  border-left: 1px solid $borderColor;
}

.change-img-order-selector {
  top: $mainMenuHeight;
  @media only screen and (max-width: $selector-breakpoint-1) {
    width: 600px;
    @include calc-mixin(left, '100% - 600px');
  }
  @media only screen and (max-width: $breakPoint-small){
    width: 100%;
    left: 0;
  }
}
