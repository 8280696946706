/********************object-menu commons***********************/
//button = btn
//dropdown list = ddl
//layout = lyt
//object = obj

.obj-menus-commons {
  height: 100%;
  &:after{
    content: " "; /* Older browser do not support empty content */
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
  @media only screen and (max-width: $breakPoint-small){
    width: 100% !important; //is overruled by .row
  }
  &__content {
    float:right;
    height: $artifactTopRow;
    position: relative;
    @media only screen and (max-width: $artifact-top-menu)  {
      float: left;
      height: 100%;
    }
    @media screen and (max-width: $breakPoint-medium-up) {
      float: right;
    }
    @media screen and (max-width: $actionMenuSmallScreen) {
      @include display-inline-flex();
      @include align-flex-items(center);
      vertical-align: middle;
      width: 100%;
    }
  }
  &__action-menu-container {
    cursor: pointer;
    height: 100%;
    float: right;
    @media screen and (max-width: $actionMenuSmallScreen) {
      @include flex-grow(1);
    }
  }
  &__action-menu-text-container {
    color: inherit;
    @media screen and (max-width: $actionMenuCollapse) {
      span{
        margin-right: 0;
      }
    }
    @media screen and (max-width: $actionMenuSmallScreen) {
      margin-right: auto;
      margin-left: auto;
    }
  }
  &__action-menu-text { line-height: 1; }

  &__action-menu {
    padding: $S $L;
    top:5px;
    color: $textColor;
    cursor: pointer;
    position: relative;
    z-index: $zIndex-artifactActionMenuButton;
    border:1px solid transparent;
    outline: none;
    i { margin-right: $XS; }

    @media screen and (max-width: 870px) {
      padding: $S;
    }
    @media screen and (max-width: $actionMenuCollapse) {
      background-color: $borderColor;
      color: $textColor;
      height: 100%;
      padding: $S $M;
      border-left: 1px solid $component-edit-blue-light;
      top: 0;
      border-right: none;
    }
    @media screen and (max-width: 1580px) and (min-width: $artifact-top-menu) {
      padding: $S;
    }
    @media screen and (max-width: $actionMenuSmallScreen) {
      padding: 0;
      width: 100%;
    }
    &.active{
      z-index: $zIndex-artifactActionMenuButton-active;
      background-color: $white;
      border-bottom: 1px solid $white;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      border-left: 1px solid rgba(0, 0, 0, 0.2);
      border-right: 1px solid rgba(0, 0, 0, 0.2);
    }
    &:hover {
      .obj-menus-commons__action-menu-text-container span{
        text-decoration: underline;
      }
    }
  }
  &__action-menu[disabled] {
    cursor: not-allowed;
    opacity: .50;
    pointer-events: none;
    filter: alpha(opacity=40);
    .obj-menus-commons__action-menu-text-container span{
      text-decoration: none;
    }
  }


  &.edit { background-color: $blue1; }

  &.on-media-page {
    @media only screen and (min-width: $breakPoint-medium-up) and (max-width: $artifact-top-menu) {
      .obj-menus-commons__content{
        float: right !important;
      }
    }
  }
}

.obj-menus-commons-ddl { //dropdown list
  position: absolute;
  top: 41.5px;
  z-index: $zIndex-artifactActionMenuDropdown;
  background-color: $white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  @include box-shadow-mixin(0 1px 2px rgba(0, 0, 0, 0.2));
  @media screen and (max-width: 700px) {
    top: 47px;
  }
  i{
    margin-right: $XS;
    font-size: $normal-icon-font-size;
  }
}
.obj-menus-commons-btn {}

.obj-menus-commons-btn-lyt { //button layout
  @extend .verticalAlign-content;
  color: $textColor;
  padding: $XS 0;
  width: 100%;
  text-align: left;
}

.obj-menus-commons-btn-text {
  display: flex;
  align-items: center;
  span {
    line-height: $normal-line-height;
  }
}

.obj-menus-commons-btn-text-color {
  span { color: $black; }

  &:hover, &:active, &:focus {
    i{ color: darken($gray2, $hover); }

    span{ color: lighten($black, $hover); }
  }
}
.obj-menus-commons-menuGroup-btn {
  @extend .obj-menus-commons-btn-lyt;
  @extend .obj-menus-commons-btn-text-color;
  span { margin-right: $S; }

  &.disabled span { color: $textColorPassive; }
}
.obj-menus-commons-sub-menu-btn {
  @extend .obj-menus-commons-btn-lyt;
  @extend .obj-menus-commons-btn-text-color;
}

.removeTextOnSmallScreens{
  @media screen and (max-width: $actionMenuCollapse) {
    display: none;
  }
}
//************************* admin-action-menu ****************************************//
.admin-stroked-button {
  margin-right: $M !important;;
  &__left-margin { margin-left: $XS; }
}

