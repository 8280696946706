/*************common classes**************/
.object-content-main-tab-button,
.object-content-dropdown-menu-button {
  padding-left: $L;
  padding-right: $L;
  display: inline-block;
  white-space: nowrap;
  cursor: pointer;
  height: $contentMenuHeight;
  &:not(.activeLeafMenu):not(.activeMenuWithSubMenus) {
    &:hover, &:active, &:focus {
      height: 59px;
      background-color: $gray;
      color: $textColor;
    }
  }
  &.activeMenuWithSubMenus{
    @extend .active-tab;
    background-color: $gray;
  }

  &.activeLeafMenu{
    @extend .active-tab;
    background-color: $white;
  }
}

.object-content-dropdown-menu,
.object-content-main-tab {
  display: inline-block;
  background-color: $gray4;
  border-right: 1px solid $content-menu-border;
  border-top: 1px solid $content-menu-border;
  white-space: nowrap;
  height: $contentMenuHeight;
  &.isEditing {
    background-color: $blue1;
    border-right: 1px solid $blue2;
    @include box-shadow-mixin(none);
    cursor: not-allowed;
    pointer-events: none;
    a{
      &:hover, &:active, &:focus,
      &.activeLeafMenu, &.activeMenuWithSubMenus {
        background-color: $blue1;
      }
      span {
        opacity: 0.6;
      }
    }
    a.disabled,
    a[disabled],
    fieldset[disabled],
    a {
      @include box-shadow-mixin(none);
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}


.object-content-tab-text {
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  @include display-flex();
  @include align-flex-items(center);
  @include justify-content(center);
  @include flex-flow(row nowrap);
  span{
    font-size: rem-calc(13px);
    line-height: $normal-line-height;
  }
  i{
    order: 1;
    font-size: $normal-icon-font-size;
  }
}
.object-content-tab-caption {
  order: 2;
}

.object-content-tab-count {
  margin-left: $L;
  order: 3;
  border: 1px solid $content-menu-border;
  background-color: $gray;
  padding-left: $XS;
  padding-right: $XS;
  height: 20px;
  @include display-flex();
  @include align-flex-items(center);
  span{
    font-size: 12px;
    line-height: 1;
  }
}
.object-content-tabs__main-tabs :first-child{
    li a {
      border-left:0;
    }
}

.active-tab{
  border-left: 1px solid $content-menu-border-active;
  border-right: 1px solid $content-menu-border-active;
  height: 60px;
  .top-line{
    height: 4px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .mat-icon{
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
