@use '@angular/material' as mat;
@import './material-3-theme';
//
//@font-face {
//  font-family: 'Material Symbols Outlined';
//  font-style: normal;
//  font-weight: 100 700;
//  font-display: block;
//  src: url(./primus-assets/fonts/material-symbols-outlined.woff2) format('woff2');
//}
//
//@font-face {
//  font-family: 'Roboto Flex';
//  src: url('./primus-assets/fonts/roboto-flex.woff2') format('woff2');
//  font-weight: 100 900;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: 'Material Icons';
//  font-style: normal;
//  font-weight: 400;
//  src: url(./primus-assets/fonts/material-icons.woff2) format('woff2');
//}

@layer m3;

/**
  Global styles for Primus + Material 3
  Theme is defined at ./material-3-theme.scss
*/

@layer m3 {
  @include mat.core();
  :host, :root {
    all: initial;
    @include mat.core-theme($primus-m3);
    @include mat.all-component-themes($primus-m3);
    @include mat.all-component-typographies($primus-m3);
    @include mat.toolbar-theme($primus-m3-dark);
    @extend .mat-typography;
    @include mat.all-component-themes($primus-m3);
    @include mat.system-level-colors($primus-m3);
    @include mat.system-level-typography($primus-m3);

    // hack: default, this is 20px, which will make the icon overshoot the entire thing.
    --mdc-switch-handle-width: 0;
    --scrollbar-thumb-radius: .5rem;

    // TODO(mat-3): this is excessive in a lot of cases. Does it need to be global?
    --mat-dialog-container-min-width: min(80vw, 500px);
    --mat-dialog-container-max-width: min(95vw, 1300px);

    .edit-dialog {
      --mat-dialog-container-min-width: min(80vw, 1300px);
    }

    --mat-tree-container-background-color: unset;
    --mat-table-background-color: var(--sys-surface-container-lowest);
  }

  @include mat.typography-hierarchy($primus-m3);

  .strong-focus {
    @include mat.strong-focus-indicators();
    @include mat.strong-focus-indicators-theme($primus-m3);
  }

  $density-scales: (-1, -2, -3, -4, -5, minimum, maximum);
  @each $scale in $density-scales {
    .density-#{$scale} {
      $density-theme: create-theme($density: $scale);
      @include mat.all-component-densities($density-theme);
    }
  }

  .m3-color-compat {
    @include mat.color-variants-backwards-compatibility($primus-m3);
    @include mat.typography-hierarchy($primus-m3, $back-compat: true);
  }

  // tailwind-esque utilities
  .bg-white {
    background-color: white;
  }

  .flex {
    display: flex;
  }

  .flex-1 {
    flex: 1;
  }

  .flex-col {
    flex-direction: column;
  }

  .flex-row {
    flex-direction: row;
  }

  .gap-1 {
    gap: 0.25rem;
  }

  .gap-2 {
    gap: 0.5rem;
  }

  .gap-4 {
    gap: 1rem;
  }

  .grow {
    flex-grow: 1;
  }

  .h-full {
    height: 100%;
  }

  .items-baseline {
    align-items: baseline;
  }

  .items-center {
    align-items: center;
  }

  .items-start {
    align-items: start;
  }

  .justify-between {
    justify-content: space-between;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-end {
    justify-content: flex-end;
  }

  .justify-start {
    justify-content: flex-start;
  }

  .m-2 {
    margin: 0.5rem;
  }

  .ml-auto {
    margin-left: auto;
  }

  .mr-2 {
    margin-right: 0.5rem;
  }

  .mr-4 {
    margin-right: 1rem;
  }

  .mt-2 {
    margin-top: 0.5rem;
  }

  .mt-4 {
    margin-top: 1rem;
  }

  .mt-6 {
    margin-top: 1.5rem;
  }

  .mt-8 {
    margin-top: 2rem;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .p-1 {
    padding: 0.25rem;
  }

  .p-2 {
    padding: 0.5rem;
  }

  .p-4 {
    padding: 1rem;
  }

  .pb-2 {
    padding-bottom: 0.5rem;
  }

  .pl-2 {
    padding-left: 0.5rem;
  }

  .rounded {
    border-radius: 8px;
  }

  .self-start {
    align-self: start;
  }

  .w-full {
    width: 100%;
  }

  .material-icons, .material-symbols-outlined, mat-icon {
    font-family: 'Material Symbols Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }

  mat-sidenav-content::-webkit-scrollbar {
    width: .375rem;
    height: .375rem;
  }

  mat-sidenav-content::-webkit-scrollbar-thumb {
    border-radius: var(--scrollbar-thumb-radius);
    background-color: var(--sys-outline-variant);
  }
}

html, body { height: 100%; }
body { margin: 0; font-family: 'Roboto Flex', "Helvetica Neue", sans-serif; }
a {
  text-decoration: none;
}

// hack: override the 280px width for this specific menu
.mat-mdc-menu-panel.common-operation-menu-styling__menu {
  max-width: initial;
}

// Very manual override because legacy... dec 2024
.common-operation-menu-styling__menu .mat-expansion-panel-content {
  --mat-list-list-item-leading-icon-start-space: 1rem;
  --mat-list-list-item-leading-icon-end-space: .5rem;
  --mdc-list-list-item-one-line-container-height: 40px;
  --mdc-list-list-item-leading-icon-size: 16px;
  .mat-expansion-panel-body mat-action-list.mdc-list {
    padding: 0!important;
  }
}
.common-operation-menu-styling__menu .mat-expansion-panel-header {
  padding: 0 1rem!important;
}

.common-operation-menu-styling__menu .mat-expansion-panel-header,
.common-operation-menu-styling__menu .mat-expansion-panel-header .mat-content mat-panel-title.mat-expansion-panel-header-title,
.mdc-list-item__primary-text {
  --mat-expansion-header-text-font: var(--sys-body-medium-font);
  --mat-expansion-header-text-size: var(--sys-body-medium-size);
  --mat-expansion-header-text-weight: var(--sys-body-medium-weight);
  --mat-expansion-header-text-line-height: var(--sys-body-medium-line-height);
  --mat-expansion-header-text-tracking: var(--sys-body-medium-tracking);
  --mdc-dialog-supporting-text-line-height: var(--sys-body-medium-line-height);
  --mdc-list-list-item-label-text-font: var(--sys-body-medium-font);
  --mdc-list-list-item-label-text-line-height: var(--sys-body-medium-line-height);
  --mdc-list-list-item-label-text-size: var(--sys-body-medium-size);
  --mdc-list-list-item-label-text-weight: var(--sys-body-medium-weight);
  --mdc-list-list-item-label-text-tracking: var(--sys-body-medium-tracking);
}
.search-menus__selection-basket-button-text,
.common-operation-menu-styling__button-text {
  font: mat.get-theme-typography($primus-m3, body-medium);
  letter-spacing: mat.get-theme-typography($primus-m3, body-medium, letter-spacing);
}


// hack: bug in safari/ios that causes z-fighting on the object-page
.mat-drawer-container {
  -webkit-overflow-scrolling: initial!important;
}

// hack: move tooltip z-index above the container overlay (default is 1000)
.cdk-overlay-container:has(.hibernate-wakeup-overlay-tooltip) {
  z-index: 10000;
}

// Common skeleton loading styles
.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(225, 19%, 89%);
  }
  100% {
    background-color: hsl(225, 19%, 94%);
  }
}
