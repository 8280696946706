/************common styling for alerts, error, warning**************/


.embedded-alert-info,
.embedded-alert-warning {
  padding-left: $S;
  @include display-flex();
  @include align-flex-items(center);
  vertical-align: middle;

  button {
    padding: $XS $S $XS 40px;
    @include display-flex();
    @include align-flex-items(center);
    cursor: pointer;
  }
  .mat-icon {
    opacity: 0.5;
    font-size: 18px;
    height: 18px;
    width: 18px;
    &:active,&:hover, &:focus {
      opacity: 1;
    }
  }

}

.embedded-alert-info {
  background-color: $embedded-alert-info_background;
  border: 1px solid $embedded-alert-info_border;
  color: $embedded-alert-info_color;
  .icon-information{
    font-size: 20px;
    margin-right: $S;
  }
  .icon-warning{
    display: none;
  }
}

.embedded-alert-warning {
  color: $warningColor;
  background-color: $warningBackgroundColor;
  border: 1px solid $warning-border;
  .icon-warning{
    font-size: 24px;
    margin-right: $S;
  }
  .icon-information{
    display: none;
  }
}

/*******************error, warning***********************/
.errorInputFrame {
  background-color: $errorBackgroundColor !important;
  border: solid 1px $errorColor !important;
}

//.warningInputFrame { //in use?
//  background-color: $warningBackgroundColor !important;
//  border: solid 1px $warningColor !important;
//}

.errorNoBorder {
  border: none !important;
}

/*****************************************/
span.error{
  margin-top:-1px;
  margin-bottom: rem-calc(16px);
  font-size: rem-calc(13px);
  font-style: italic;
  color:$white;
  background-color: $orange-color;
}

.errorReport{ //in use?
  position: absolute;
  z-index: $zIndex-errorReport;
  width: 100%;
  padding:$S $S $S 43px;
}
