/*****************vendor prefixes*************************/
@mixin calc-mixin($property, $expression) {
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: -moz-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

@mixin placeholderColor($color) {
  &::-webkit-input-placeholder{
    color:$color;
  }
  &:-moz-placeholder{
    color:$color;
  }
}

@mixin box-shadow-mixin($expression) {
  box-shadow: $expression;
  -moz-box-shadow: $expression;
  -webkit-box-shadow: $expression;
}

@mixin border-radius-mixin($expression) {
  -webkit-border-radius: $expression;
  -moz-border-radius: $expression;
  border-radius: $expression;
}
@mixin box-sizing-mixin($expression) {
  -webkit-box-sizing: $expression;
  -moz-box-sizing: $expression;
  box-sizing: $expression;
}
@mixin appearance-mixin($expression) {
  -webkit-appearance: $expression;
  -moz-appearance: $expression;
  appearance: $expression;
}
@mixin ellipsis-mixin(){
  overflow: hidden; /* older browsers */
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis; /* will make [...] at the end */
}
@mixin ellipsis-multiple-lines-2-mixin($line-clamp){
  @include ellipsis-mixin();
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-line-clamp: $line-clamp;
  word-wrap: break-word;
  white-space: normal;
}
@mixin user-select-text(){
  cursor: text;
  -webkit-user-select: text; /* Chrome, Safari, and Opera */
  -moz-user-select: all; /* Firefox */
  -ms-user-select: all; /* IE 10+ */
  user-select: text; /* Likely future */
}
@mixin display-flex(){
  display: -webkit-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@mixin display-inline-flex(){ /*display flex container inline*/
  display: inline-flex;
  display: -webkit-inline-flex; /*Safari*/
  flex-wrap: wrap;
}
@mixin align-flex-items($value){
  align-items: $value;
  -webkit-align-items: $value;
}
@mixin align-self($value){
  align-self: $value;
  -webkit-align-self: $value;
}
@mixin justify-content($value){
  justify-content: $value;
  -webkit-justify-content: $value;
}
@mixin flex-flow($value){
  flex-flow: $value;
  -webkit-flex-flow: $value;
}
@mixin flex-grow($value){
  flex-grow: $value;
  -webkit-flex-grow: $value;
}
@mixin flex-shrink($value){
  flex-shrink: $value;
  -webkit-flex-shrink: $value;
}
@mixin flex($value){
  flex: $value;
  -webkit-flex: $value;
}
@mixin flex-basis($value){
  flex-basis: $value;
  -webkit-flex-basis: $value;
}
@mixin verticalAlign($expression){
  position: relative;
  top: 50%;
  -webkit-transform: $expression;
  -moz-transform: $expression;
  -ms-transform: $expression;
  -o-transform: $expression;
  transform: $expression;
}
