@use '@angular/material' as mat;
@import '../../material-3-theme';

  body {
    font: mat.get-theme-typography($primus-m3, body-medium);
    letter-spacing: mat.get-theme-typography($primus-m3, body-medium, letter-spacing);
    color: mat.get-theme-color($primus-m3, on-surface);
    line-height: mat.get-theme-typography($primus-m3, body-medium, line-height);
    margin: 0;
    @media screen and (max-width: $breakPoint-medium-up) {
      font-size: rem-calc($smallScreenFontRemCalc);
    }
  }

  label, textarea, input[type="text"] {
    font-size: rem-calc($normal-font-size-rem);
    @media screen and (max-width: $breakPoint-medium-up) {
      font-size: rem-calc($smallScreenFontRemCalc);
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto Flex', sans-serif;
    color: $textColor;
    margin-top: .2rem;
    margin-bottom: .4rem;
    line-height: 1.4;
  }

  h1, .h1 {
    font-size: rem-calc(32);
  }

  h2, .h2 {
    font-size: rem-calc(16);
  }

  h3, .h3 {
    font-size: rem-calc(22);

  }

  h4, .h4 {
    font-size: rem-calc($normal-font-size-rem);
    @media screen and (max-width: $breakPoint-medium-up) {
      font-size: rem-calc($smallScreenFontRemCalc);
      line-height: $normal-line-height;
    }
  }

  h5, .h5 {
    font-size: rem-calc(18);
    line-height: 24px;
  }

  ul, ol, dl {
    font-size: rem-calc($normal-font-size-rem);
    font-family: 'Roboto Flex', sans-serif;
    @media screen and (max-width: $breakPoint-medium-up) {
      font-size: rem-calc($smallScreenFontRemCalc);
    }
  }

  .light {
    font-weight: 300;
  }

  .medium {
    font-weight: 500;
  }

  .bold {
    font-weight: 600;
  }

  .uppercase {
    text-transform: uppercase;
  }

  strong {
    font-weight: 600;
  }

  .span {
    font-size: rem-calc($normal-font-size-rem);
  }

  .text-link {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .headlines.h1 {
    @extend h1;
  }

  .headlines.h2 {
    @extend h2;
  }

  .headlines.h3 {
    @extend h3;
  }

  .headlines.h4 {
    @extend h4;
  }

  .headlines.h5 {
    @extend h5;
  }

  .headlines.h6 {
    @extend h6;
  }

  .legacy {
    h1, h2, h3, h4, h5, h6, a {
      text-decoration: none;
      //font-style: normal;
      //font-weight: 400;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
    }
  }

  textarea {
    min-height: 50px;
  }

  /*basic styling on all inputs elements */
  input[type="text"]:not(.mat-mdc-input-element),
  input[type="password"]:not(.mat-mdc-input-element),
  input[type="date"]:not(.mat-mdc-input-element),
  input[type="datetime"]:not(.mat-mdc-input-element),
  input[type="datetime-local"]:not(.mat-mdc-input-element),
  input[type="month"]:not(.mat-mdc-input-element),
  input[type="week"]:not(.mat-mdc-input-element),
  input[type="email"]:not(.mat-mdc-input-element),
  input[type="number"]:not(.mat-mdc-input-element),
  input[type="search"]:not(.mat-mdc-input-element),
  input[type="tel"]:not(.mat-mdc-input-element),
  input[type="time"]:not(.mat-mdc-input-element),
  input[type="url"]:not(.mat-mdc-input-element),
  textarea,
  select {
    @include box-sizing-mixin(border-box);
    @include box-shadow-mixin(inset 0 1px 2px rgba(0, 0, 0, .1));
    @include appearance-mixin(none);
    background-color: $white;
    font-family: inherit;
    color: $textColor;
    border: 1px solid $gray3;
    display: block;
    margin: 0;
    padding: $S 25px $S $M;
    height: $normal-input-height;
    width: 100%;
    transition: box-shadow 0.45s, border-color 0.45s ease-in-out;
    resize: vertical;
    @include placeholderColor($textColorPlaceholderInput);

    &:focus {
      box-shadow: 0 0 5px $image-background;
      background-color: $input-focus-bg-color;
      border-color: $image-background;
      outline: none;
    }
  }

