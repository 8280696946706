@use '@angular/material' as mat;
@import "../../material-3-theme";

/****************common styling for landing-pages****************************/

/************common styling for object-page, media-page**************/
//.landing-page-top {
//  height: $artifactTopRow;
//  //background-color: $component-edit-blue-light;
//  border-bottom: 1px solid $borderColor;
//
//  &__action-menu {
//    @media only screen and (max-width: $artifact-top-menu)  {
//      //background-color: transparent;
//      border-right: 1px solid $borderColor;
//    }
//    @media only screen and (max-width: 760px)  {
//      border: 1px solid $component-edit-blue-light;
//      //background-color: $borderColor;
//    }
//  }
//}

.landing-page-main-content {
  //background-color: $white;
  @include display-inline-flex();
  height: 100%;

  &__content {
    width: 100%;
    @include display-flex();
    @include flex-flow(column nowrap);
  }
  &__overview-fields {
    padding: 0 $L $top-section-margin $L;
    //background-color: $background;
    height: 300px;
    width: 100%;
    @media only screen and (max-width: $fieldSmall) {
      max-height: 500px;
      height: auto;
    }
    .authority-ribbon-container{
      width: 85px;
      height: 85px;
      right: $L;
      top: 0;
    }
    .authority-ribbon{
      top: 20px;
      border: 1px solid $authority-ribbon-border;
      font-size: 12px;
    }
  }
  &__overview-fields-item {
    margin-top: $top-section-margin;
    display: block;

    .noShowMore, .topSectionTextField{
      float: left;
      margin-right: $XS;
      max-width: 90%;
      height: 16px;
      @include ellipsis-mixin;
    }
    .object-url__object-link a { line-height: $normal-line-height; }
  }
}
.landing-page-lower {
  width: 100%;
  @include flex-grow(1);
  &__container {
    height: 100%;
    background-color: $white;
    padding-bottom:$XS;
    @include display-flex();
    @include flex-flow(column nowrap);
  }

  &__content-tabs { width: 100%; }

  &__content-tabs-container{
    min-height: 61px;
    background-color: $background;
    width: 100%;
    &.isEditing{
      background-color: $blue2;
    }
  }

  &__content {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 50px;
    @include display-flex();
    @include flex-flow(column nowrap);
    @include flex-grow(1);
    @include justify-content(space-between);
    height: 100%;
    .search-view { margin-top: 0; }
  }

  &__content-list {
    display: inline-block;
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0 $L;
    &__container { min-height: 540px; }
  }
  &__overview {
    width: 100%;
    padding: 0 $L;
  }
  &__failed {
    width: 100%;
  }
}
.object-and-media-page {
  //height: 100%;
  min-height: 100%;
  position: relative;
  background-color: $background;
  @include display-flex();
  &.imageFullScreenOpen { max-height: 100vh; }

  &__container {
    float: left;
    width: 100%;
  }
  &__content {
    position: relative;
    width: 100%;
    height: 100%;
    @include calc-mixin(min-height, '100vh - #{$mainMenuHeight}');

    @media only screen and (max-width: 1024px) {
      overflow-y:visible;
      display:block;
    }
  }
  &__media-carousel {
    @media only screen and (max-width: 1024px) {
      height: auto;
    }
  }
  &__template {
    height: $artifactTopRow;
    background-color: $component-edit-blue-light;
    border-bottom: 1px solid $borderColor;
    float: left;
    width: 100%;
    @media only screen and (max-width: 1024px)  {
      background-color: transparent;
    }
  }
  &__media-carousel-content{
    max-height: 91%;
    position: fixed;
    top: 99px;
    bottom: 0;
    width: inherit;
    z-index: $zIndex-artifactImgContainer;
    @media only screen and (max-width: 1024px) {
      min-height: 100%;
      position: relative;
      top: 0;
    }
  }
  &.edit{
    background-color: $blue2;
    .object-and-media-page__media-carousel,
    .landing-page-lower,
    .landing-page-lower__container,
    .object-and-media-page__template,
    .landing-page-top{
      background-color: $blue1;
    }
    .landing-page-main-content__overview-fields { background-color: $blue2; }
    .landing-page-lower__content{ margin-bottom: 150px; }
  }
}
/****************************************************/

.image-full-screen-container {
  z-index: 1000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
/*************************/

@include keyframes-mixin(appear_anim){
  from { opacity:0; }
  to { opacity:1; }
}
@include keyframes-mixin(disappear_anim){
  from { opacity:1; }
  to { opacity:0; }
}

/********************common styling for admin-pages****************************/
.admin-row{
  @include display-flex();
  @include align-flex-items(stretch);
  @include justify-content(space-around);
  @include flex-flow(row wrap);
  margin-left: -$L;
  @media only screen and (max-width: $admin-card-breakpoint) {
    margin-left:0;
  }
}

.admin-column {
  @include flex-grow(1);
  @include flex-basis($L);
  margin-left: $L;
  min-width: 450px;

  @media only screen and (max-width: $admin-card-breakpoint) {
    min-width: auto;
    width: 100%;
    margin-left: 0;
    @include flex-basis(250px);
  }

  .admin-email{
    margin-left: $XS;
    color: mat.get-theme-color($primus-m3, primary);
  }
}

.mat-mdc-card.admin-card {
  border: 1px solid mat.get-theme-color($primus-m3, outline-variant);
  background-color: mat.get-theme-color($primus-m3, surface-container-lowest);
  padding: $L;
  @include display-flex();
  @include flex-flow(column);
  margin-top: $S;
  margin-bottom: $S;

  @media only screen and (max-width: $admin-card-breakpoint) {
    padding: $L 0;
  }

  .mat-mdc-list-item {
    padding: $S 0;

    span, a { font-size: 14px; }
  }
}
.admin-card__header { margin-bottom: $L; }
.admin-card__content { flex-grow: 1; }

.admin-fq{
  &__hr {
    width: 20px;
    border-width: 1px;
    border-color: $borderColor-top;
    margin: $S 0 $S 0;
    float: left;
  }
  li{
    padding-top: $XS;
    padding-bottom: $XS;
    float: left;
    width: 100%;
    &:last-of-type {
      .admin-fq__hr { display: none; }
    }
  }
  &__button{
    float: left;
    padding-bottom: $S;
    width: 100%;
    i{
      font-size: 11px;
      margin-left: $XS;
      float: right;
    }
  }
  &__drop-down {
    float: left;
    width: 100%;
    padding-bottom: $XS;
    a {
      &:hover, &:active, &:focus {
        color: $dark-blue;
        text-decoration: underline;
      }
    }
    li { padding-left: $S; }
  }
}
.admin-help,
.admin-concept-list-page__last-created{
  li {
    padding-top: $XS;
    padding-bottom: $XS;
  }
  span{ float: left; }
}

.admin-header-container {
  position: fixed;
  top: $topHeaderHeight;
  left:0;
  right: 0;
  z-index: $zIndex-adminHeader;
}
/**************************************************/
.admin-content-top {
  width: 100%;
  height: 75px;
  @include display-flex();
  @include align-flex-items(center);
  position: sticky;
  top: 0;
  background: $white;
  z-index: 2;

  h5 {
    font-size: 22px;
    @include flex-grow(1);
  }
}
.admin-scroll-layout {
  &::-webkit-scrollbar-track{
    background-color: $gray;
    padding-top: 75px;
    border-left: 1px solid $border-color;
  }
  &::-webkit-scrollbar{
    width: 9px;
  }
  &::-webkit-scrollbar-thumb{
    border: 2px solid $gray;
    border-radius: 4px;
    background-color:  $scrollbar-thumb-admin;
  }
  &::-webkit-scrollbar-corner {
    background-color: $gray;
  }
  &::-webkit-scrollbar-track:horizontal{
    background-color: $gray;
    border-top: 1px solid $border-color;
  }
  &::-webkit-scrollbar:horizontal{
    height: 9px;
  }
  &::-webkit-scrollbar-thumb:horizontal{
    border: 2px solid $gray;
    border-radius: 4px;
    background-color:  $scrollbar-thumb-admin;
  }

  /* TODO(mat-3): compensate font weight to match old global rules */
  tbody {
    --mat-table-row-item-label-text-weight: 700;
  }
}
.admin-action-toolbar {
  height: 65px;
  //background-color: $white;
  // TODO(mat-3): background-color fix
  --mat-paginator-container-background-color: var(--sys-surface-container-lowest);
  background-color: var(--sys-surface-container-lowest);

  @include calc-mixin(width, '100% - 2px');
  border-top: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;
  position: absolute;
  bottom: 0;
  z-index: 100;
  @include display-flex();
  @include flex-flow(row nowrap);
  @include align-flex-items(center);
  @include justify-content(space-between);
  padding-left: $M;
}
.admin-table-select-all-name {
  display: block;
  text-align: center;
  font-size: 10px;
  text-transform: uppercase;
  width: 100%;
  &.disabled { color: $textColorPlaceholderInput; }
}

/**********template-group, template-model**************/
.template-list{
  &__content{
    float: left;
    width: 100%;
  }
  &__item{
    &.active{
      background-color: $component-edit-blue-light;
      border: 1px solid $component-edit-blue-dark;
    }
    background-color: $top-header-background;
    float: left;
    width: 100%;
    margin-top: $L;
    padding: $L;
    border: 1px solid $admin-border;
    h2{ margin-bottom: $L; }
  }
  &__object-overview {
    float: left;
    @include calc-mixin(width, '100% - 65px');
    @media only screen and (max-width: $searchListThumbs) {
      width: 100%;
    }
  }
  &__settings {
    position: relative;
    float: left;
    cursor: pointer;
    width: 65px;
    height: 25px;

    @media only screen and (max-width: $searchListThumbs) {
      position: absolute;
      top: 10px;
      right: 0;
    }
  }
}

@media only screen and (min-width: 2000px) {
  .template-list {
    &__content {
      @include display-flex();
      @include align-flex-items(stretch);
      @include flex-flow(row wrap);
      @include justify-content(left);
      margin-left: -$L;
    }
    &__item{
      width: 50%;
      @include calc-mixin(flex-basis, '50% - #{$L}');
      margin-left: $L;
    }

  }

}
/************ object-content-list commons *******************************/
.object-content-list {
  &__top {
    @include display-flex();
    @include flex-flow(row wrap);
    @include align-flex-items(center);
    gap: 0.5rem;
    margin-top: $L;
    font-size: $normal-font-size;
  }
  &__search-query {
    //height: $searchPage-actionMenu;
    order: 2;
    @include flex-grow(1);
    margin: 0 $L;

    &.noLeftMargin { margin-left: 0; }

    @media only screen and (max-width: 1600px) {
      order: 1;
      margin: 0 0 $L 0;
      width: 100%;
    }
  }
  &__top-button {
    @include display-flex();
    //height: 100%;
    //width: 206px;
    //cursor: pointer;
    //font-size: $normal-font-size;
    //position: relative;

    @media only screen and (max-width: $searchTopMenuButtonsCollapse) {
      width: auto;
      @include justify-content(center);
      vertical-align: middle;
      @include flex-flow(column nowrap);
      margin-left: $XS;
      min-width: 120px;
    }
    @media only screen and (max-width: $breakPoint-small) {
      margin-left: 0;
    }

    @media only screen and (max-width: $searchTopMenuButtonsCollapseSmall) {
      min-width: initial;
    }

    &.disable {
      //background: $searchActionMenuButton-disabled;
      cursor: auto;
    }
  }

  &__top-button-filter {
    order: 4;
    //height: $searchPage-actionMenu;
  }

  &__top-button-sort {
    order: 3;
    //height: $searchPage-actionMenu;
  }
  &__top-button-actions {
    order: 1;
    //height: $searchPage-actionMenu;
    @media only screen and (max-width: 1600px) {
      order: 2;
      margin-right: auto;
    }

    &.active .object-content-list__top-button:not(.disable){
      //background: $searchActionMenuButton-active;
    }

    @media only screen and (max-width: $searchTopMenuButtonsCollapse) {
      //height: $searchPage-actionMenu-SmallScreen;
      .object-content-list__select-all {
        //width: 54px;
        //height: 34px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 11;
      }
      .object-content-list__menu-actions {
        width: 100%;
      }
    }
  }
  &__menu-actions {
    //position: relative;
    //height: 100%;
    @include flex-grow(1);
  }
}
